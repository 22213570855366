import React, { FC, useState, useRef, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  TextField,
  IconButton,
  Slider,
  Select,
  MenuItem,
  Tooltip,
  useMediaQuery,
  useTheme,
  CircularProgress,
  InputAdornment,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import SendIcon from '@mui/icons-material/Send';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

interface ChatResponse {
  question: string;
  answer: string;
}

const TalkToBaseLLMsPage: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [question, setQuestion] = useState<string>('');
  const [responses, setResponses] = useState<ChatResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const [model, setModel] = useState<string>('gpt-4');
  const [temperature, setTemperature] = useState<number>(0.2);
  const [maxTokens, setMaxTokens] = useState<number>(750);

  const responsesEndRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    if (responsesEndRef.current) {
      responsesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [responses]);

  const handleSubmit = async () => {
    if (!question.trim()) {
      setError('Por favor, escreva uma pergunta.');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const payload = {
        text: question.trim(),
        model,
        temperature,
        max_tokens_response: maxTokens,
      };

      const res = await axios.post('https://sareg-qa-2979ad-api.azurewebsites.net/chat', payload);

      const answer = res.data.message;

      setResponses((prevResponses) => [
        ...prevResponses,
        { question: question.trim(), answer },
      ]);

      setQuestion('');
    } catch (err: any) {
      console.error('Erro ao enviar a pergunta:', err);
      setError('Ocorreu um erro ao enviar a pergunta.');
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSubmit();
    }
  };

  const selectedOption = location.state?.selectedOption || 'N/A';

  const renderField = (
    label: string,
    tooltip: string,
    element: React.ReactNode
  ) => (
    <>
      <Box display="flex" alignItems="center" mt={2}>
        <Typography variant="subtitle2" sx={{ flexGrow: 1 }}>
          {label}
        </Typography>
        <Tooltip title={tooltip}>
          <IconButton size="small" aria-label={`Help for ${label}`}>
            <HelpOutlineIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </Box>
      {element}
    </>
  );

  return (
    <Box display="flex" height="100vh" overflow="hidden">
      <Box
        sx={{
          width: isMobile ? '100%' : '300px',
          padding: '16px',
          height: '100vh',
          backgroundColor: '#f6f8fa',
          borderRight: isMobile ? 'none' : '1px solid #e0e0e0',
          borderBottom: isMobile ? '1px solid #e0e0e0' : 'none',
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          overflowY: 'auto',
          mb: isMobile ? 2 : 0,
        }}
      >
        <Typography variant="h6" style={{ marginTop: '2rem' }} gutterBottom>
          Customize your app
        </Typography>

        <div>
          Instance: <label>{selectedOption}</label>
        </div>

        {['model', 'temperature', 'maxTokens'].map((field) => {
          if (field === 'model') {
            return renderField(
              'Model',
              'Select the model to use',
              <Select
                value={model}
                onChange={(e) => setModel(e.target.value as string)}
                fullWidth
              >
                <MenuItem value="gpt-4">gpt-4</MenuItem>
                <MenuItem value="gpt-4o-mini">gpt-4o-mini</MenuItem>
              </Select>
            );
          }

          if (field === 'temperature') {
            return renderField(
              'Temperature',
              'Controls the randomness of responses',
              <>
                <Slider
                  value={temperature}
                  onChange={(e, value) => setTemperature(value as number)}
                  min={0}
                  max={1}
                  step={0.01}
                  sx={{ width: '100%' }}
                  aria-labelledby="temperature-slider"
                />
                <Typography variant="body2" color="textSecondary">
                  {temperature.toFixed(2)}
                </Typography>
              </>
            );
          }

          if (field === 'maxTokens') {
            return renderField(
              'Max tokens response',
              'Maximum number of tokens in the response',
              <>
                <Slider
                  value={maxTokens}
                  onChange={(e, value) => setMaxTokens(value as number)}
                  min={250}
                  max={4000}
                  step={50}
                  sx={{ width: '100%' }}
                  aria-labelledby="max-tokens-slider"
                />
                <Typography variant="body2" color="textSecondary">
                  {maxTokens}
                </Typography>
              </>
            );
          }

          return null;
        })}
      </Box>

      <Box flexGrow={1} display="flex" flexDirection="column">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: isMobile ? 2 : 4,
            borderBottom: '1px solid #ddd',
            backgroundColor: '#fff',
          }}
        >
          <IconButton
            onClick={() => navigate('/')}
            aria-label="Home"
            sx={{ alignSelf: 'flex-start' }}
          >
            <HomeIcon />
          </IconButton>

          <Typography variant="h4" sx={{ ml: 2 }}>
            Fale com LLMs de forma segura
          </Typography>
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            padding: isMobile ? 2 : 4,
          }}
        >
          <Paper
            sx={{
              p: 2,
              backgroundColor: '#fff4e5',
              border: '1px solid #fff1c4',
              borderRadius: '8px',
              mb: 2,
            }}
          >
            <Typography variant="body2" color="textSecondary">
              Por favor, escreva sua pergunta abaixo.
            </Typography>
          </Paper>

          <Box sx={{ flexGrow: 1, overflowY: 'auto', pr: 1, mb: 2 }}>
            {responses.map((chat, index) => (
              <Box key={index} sx={{ mb: 2 }}>
                <Typography variant="subtitle2" color="textSecondary">
                  Você:
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  {chat.question}
                </Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  AI:
                </Typography>
                <Typography variant="body1">{chat.answer}</Typography>
              </Box>
            ))}
            <div ref={responsesEndRef} />
          </Box>
        </Box>

        <Box
          sx={{
            padding: isMobile ? 2 : 4,
            borderTop: '1px solid #ddd',
            backgroundColor: '#f6f8fa',
          }}
        >
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Escreva sua pergunta"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            onKeyPress={handleKeyPress}
            multiline
            minRows={2}
            maxRows={4}
            sx={{
              backgroundColor: '#fff',
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    aria-label="Enviar pergunta"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <SendIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        {error && (
          <Typography variant="body2" color="error" sx={{ mt: 2, px: 4 }}>
            {error}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default TalkToBaseLLMsPage;
