import React, { RefObject } from 'react';
import {
  Box,
  Typography,
  IconButton,
  TextField,
  CircularProgress,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

interface ChatResponse {
  question: string;
  answers: { fileName: string; result: string }[];
}

interface Props {
  responses: ChatResponse[];
  question: string;
  setQuestion: (question: string) => void;
  handleUpload: () => void;
  loading: boolean;
  responsesEndRef: RefObject<HTMLDivElement>;
  isMobile: boolean;
}

const ResponseDisplay: React.FC<Props> = ({
  responses,
  question,
  setQuestion,
  handleUpload,
  loading,
  responsesEndRef,
  isMobile,
}) => (
  <Box sx={{ flexGrow: 1, overflowY: 'auto', pr: 1, mb: 2 }}>
    {responses.map((chat, index) => (
      <Box key={index} sx={{ mb: 2 }}>
        <Typography variant="subtitle2" color="textSecondary">
          You:
        </Typography>
        <Typography variant="body1" sx={{ mb: 1 }}>
          {chat.question}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          AI responses:
        </Typography>
        {Array.isArray(chat.answers) &&
          chat.answers.map((answer, answerIndex) => (
            <Box key={answerIndex} sx={{ mb: 1 }}>
              <Typography variant="subtitle2" color="textSecondary">
                File: {answer.fileName}
              </Typography>
              <Typography variant="body1">{answer.result}</Typography>
            </Box>
          ))}
      </Box>
    ))}
    <div ref={responsesEndRef} />

    <Box display="flex" alignItems="center" component="form" sx={{ maxWidth: '100%' }}>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Write your question about the file(s)"
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
        multiline
        minRows={isMobile ? 1 : 2}
        maxRows={isMobile ? 3 : 4}
        sx={{
          borderRadius: '8px',
          '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
          },
          fontSize: isMobile ? '0.75rem' : '1rem',
        }}
      />
      <IconButton
        color="primary"
        aria-label="send"
        onClick={handleUpload}
        sx={{ ml: 1 }}
        disabled={loading}
      >
        {loading ? <CircularProgress size={24} color="inherit" /> : <SendIcon />}
      </IconButton>
    </Box>
  </Box>
);

export default ResponseDisplay;
