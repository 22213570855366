import React from 'react';
import { Box, Typography } from '@mui/material';

const Instructions: React.FC = () => (
  <Box
    sx={{
      color: '#646464',
      marginBottom: '1rem',
      padding: '1rem',
      border: '1px solid #e0e0e0',
      borderRadius: '8px',
    }}
  >
    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
      How to ask a question about the file?
    </Typography>
    <Typography variant="body2">
      To ask questions about a file, whether it's a PDF, .docx, etc., upload the file and mention the file name in your question. Example: "What is the termination clause in the contract.pdf?"
    </Typography>
  </Box>
);

export default Instructions;
