import React from 'react';
import { Box, Button } from '@mui/material';

interface Props {
  isMobile: boolean;
  clearHistory: () => void;
  openHistoryModal: () => void;
}

const FileUploadActions: React.FC<Props> = ({ isMobile, clearHistory, openHistoryModal }) => (
  <Box
    sx={{
      display: 'flex',
      gap: 2,
      flexDirection: isMobile ? 'column' : 'row',
      mb: 2,
    }}
  >
    <Button
      variant="contained"
      color="primary"
      fullWidth={isMobile}
      sx={{
        borderRadius: '12px',
        padding: '6px 12px',
        fontSize: isMobile ? '0.75rem' : '0.875rem',
        textTransform: 'none',
        alignSelf: 'flex-start',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
      }}
      onClick={clearHistory}
    >
      Clear History
    </Button>
    {/* <Button
      variant="contained"
      color="primary"
      fullWidth={isMobile}
      sx={{
        borderRadius: '12px',
        padding: '6px 12px',
        fontSize: isMobile ? '0.75rem' : '0.875rem',
        textTransform: 'none',
        alignSelf: 'flex-start',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
      }}
      onClick={openHistoryModal}
    >
      View History
    </Button> */}
  </Box>
);

export default FileUploadActions;
