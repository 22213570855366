import React, { FC } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
  Grid,
  useMediaQuery,
  useTheme,
  FormControl,
  InputLabel,
  Snackbar,
  Alert,
  InputAdornment,
  Dialog,
  DialogContent,
  CircularProgress,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import PaletteIcon from '@mui/icons-material/Palette';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';

import { useImageGeneration } from '../hooks/useImageGeneration';
import { useLocation } from 'react-router-dom';

const GenerateImagesPage: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const selectedOption = location.state?.selectedOption || 'N/A';

  const {
    prompt,
    setPrompt,
    size,
    setSize,
    style,
    setStyle,
    loading,
    imageURL,
    snackbar,
    modalOpen,
    setSnackbar,
    handleGenerateImage,
    handleOpenModal,
    handleCloseModal,
  } = useImageGeneration();

  return (
    <Box display="flex" flexDirection={isMobile ? 'column' : 'row'}>
      <Box
        sx={{
          width: isMobile ? '100%' : '300px',
          padding: '16px',
          height: isMobile ? 'auto' : '100vh',
          backgroundColor: '#f6f8fa',
          borderRight: isMobile ? 'none' : '1px solid #e0e0e0',
          borderBottom: isMobile ? '1px solid #e0e0e0' : 'none',
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}
      >
        <Typography variant="h6" gutterBottom>
          Customize your app
        </Typography>

        <div>
          Instance: <label>{selectedOption}</label>
        </div>

        <Box sx={{ mb: 2 }}>
          <FormControl fullWidth>
            <InputLabel id="size-label">Image size</InputLabel>
            <Select
              labelId="size-label"
              value={size}
              label="Image size"
              onChange={(e) => setSize(e.target.value)}
            >
              <MenuItem value="1024x1024">1024x1024</MenuItem>
              <MenuItem value="1792x1024">1792x1024</MenuItem>
              <MenuItem value="1024x1792">1024x1792</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ mb: 2 }}>
          <FormControl fullWidth>
            <InputLabel id="quality-label">Image quality</InputLabel>
            <Select
              labelId="quality-label"
              value="hd"
              label="Image quality"
              disabled
            >
              <MenuItem value="hd">HD</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box>
          <FormControl fullWidth>
            <InputLabel id="style-label">Image style</InputLabel>
            <Select
              labelId="style-label"
              value={style}
              label="Image style"
              onChange={(e) => setStyle(e.target.value)}
            >
              <MenuItem value="vivid">Vivid</MenuItem>
              <MenuItem value="natural">Natural</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>

      <Box flexGrow={1} p={isMobile ? 2 : 4}>
        <IconButton onClick={() => window.location.href = '/'} aria-label="Home">
          <HomeIcon />
        </IconButton>

        <Typography variant="h4" sx={{ mt: 2, mb: 4 }} display="flex" alignItems="center">
          <PaletteIcon fontSize="large" sx={{ mr: 1 }} />
          Generate images using DALL-E
        </Typography>

        <Grid container spacing={2} justifyContent={isMobile ? 'center' : 'space-between'} my={3}>
          <Grid item xs={12} sm={6} md={3}>
            <Button variant="outlined" fullWidth onClick={() => setPrompt('A futuristic city')}>
              A futuristic city
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Button variant="outlined" fullWidth onClick={() => setPrompt('Scientists working in a lab')}>
              Scientists working in a lab
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Button variant="outlined" fullWidth onClick={() => setPrompt('A lake in the middle of a forest')}>
              A lake in the middle of a forest
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Button variant="outlined" fullWidth onClick={() => setPrompt('A completely snowed landscape')}>
              A completely snowed landscape
            </Button>
          </Grid>
        </Grid>

        <Box display="flex" alignItems="center" mb={2}>
          <Typography variant="body1" sx={{ mr: 1 }}>Generate prompts for your topic</Typography>
          <Tooltip title="Enter a topic to generate prompts">
            <IconButton size="small">
              <HelpOutlineIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Box>

        <TextField
          fullWidth
          variant="outlined"
          placeholder="Enter your topic"
          sx={{ mb: 3 }}
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="generate image"
                  onClick={handleGenerateImage}
                  edge="end"
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} /> : <SendIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleGenerateImage();
            }
          }}
        />

        {imageURL && (
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              Generated Image:
            </Typography>
            <img
              src={imageURL}
              alt="Generated"
              style={{ width: '100%', maxHeight: '500px', objectFit: 'contain', cursor: 'pointer' }}
              onClick={handleOpenModal}
            />
          </Box>
        )}

        <Dialog
          open={modalOpen}
          onClose={handleCloseModal}
          maxWidth="lg"
          fullWidth
          aria-labelledby="generated-image-modal"
        >
          <Box sx={{ position: 'absolute', top: 8, right: 8 }}>
            <IconButton onClick={handleCloseModal} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent sx={{ p: 0 }}>
            <img
              src={imageURL || ''}
              alt="Generated Enlarged"
              style={{ width: '100%', height: 'auto' }}
            />
          </DialogContent>
        </Dialog>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default GenerateImagesPage;
