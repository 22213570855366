// src/components/FeatureButton.tsx
import React, { FC } from 'react';
import { Button, Box, useMediaQuery, useTheme } from '@mui/material';

interface FeatureButtonProps {
  title: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean; // Adiciona a prop disabled
}

const FeatureButton: FC<FeatureButtonProps> = ({ title, icon, onClick, disabled = false }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      my={2}
      display="flex"
      justifyContent="center"
      width="100%"
    >
      <Button
        variant="contained"
        color="primary"
        onClick={onClick}
        disabled={disabled} // Passa a prop disabled para o Button
        sx={{
          width: isMobile ? '100%' : '300px',
          maxWidth: '300px',
          justifyContent: 'center',
          padding: '10px 16px',
          textTransform: 'none',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {icon && <Box component="span" mr={1}>{icon}</Box>}
        {title}
      </Button>
    </Box>
  );
};

export default FeatureButton;
