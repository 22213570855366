import React, { useState, useEffect } from 'react';
import { Typography, Button, Box } from '@mui/material';
import { getAllUsers, addUser, updateUser, deleteUser } from '../services/userService';
import UserModal from '../components/UserModal';
import UserTable from '../components/UserTable';

interface User {
    id: string;
    email: string;
    name: string;
    roles: string[];
}

const AdminPage: React.FC = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [newUser, setNewUser] = useState({ email: '', name: '', roles: '' });
    const [editUser, setEditUser] = useState<User | null>(null);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const usersData = await getAllUsers();
                setUsers(usersData);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchUsers();
    }, []);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setNewUser((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (editUser) {
            setEditUser({
                ...editUser,
                [name]: name === 'roles' ? value.split(',').map((role) => role.trim()) : value,
            });
        }
    };

    const handleAddUser = async () => {
        try {
            const formattedUser = {
                email: newUser.email,
                name: newUser.name,
                roles: newUser.roles.split(',').map(role => role.trim()),
            };
            const addedUser = await addUser(formattedUser);
            setUsers([...users, addedUser]);
            setIsAddModalOpen(false);
            setNewUser({ email: '', name: '', roles: '' });
        } catch (error) {
            console.error('Error adding user:', error);
        }
    };

    const handleEditUser = async () => {
        if (editUser) {
            try {
                const updatedUser = await updateUser(editUser.id, {
                    email: editUser.email,
                    name: editUser.name,
                    roles: editUser.roles,
                });
                setUsers(users.map((user) => (user.id === updatedUser.id ? updatedUser : user)));
                setIsEditModalOpen(false);
                setEditUser(null);
            } catch (error) {
                console.error('Error updating user:', error);
            }
        }
    };

    const handleDeleteUser = async (id: string) => {
        try {
            await deleteUser(id);
            setUsers(users.filter((user) => user.id !== id));
        } catch (error) {
            console.error('Error deleting user:', error);
        }
    };

    return (
        <Box sx={{ padding: 4 }}>
            <Typography variant="h4" gutterBottom>
                Administration Panel
            </Typography>
            <Button variant="contained" color="primary" onClick={() => setIsAddModalOpen(true)} sx={{ mb: 2 }}>
                Add User
            </Button>

            <UserTable
                users={users}
                onEdit={(user) => {
                    setEditUser(user);
                    setIsEditModalOpen(true);
                }}
                onDelete={handleDeleteUser}
            />

            <UserModal
                open={isAddModalOpen}
                onClose={() => setIsAddModalOpen(false)}
                title="Add New User"
                user={newUser}
                onInputChange={handleInputChange}
                onSubmit={handleAddUser}
            />

            {editUser && (
                <UserModal
                    open={isEditModalOpen}
                    onClose={() => setIsEditModalOpen(false)}
                    title="Edit User"
                    user={{
                        ...editUser,
                        roles: editUser?.roles.join(', ') || '',
                    }}
                    onInputChange={handleEditInputChange}
                    onSubmit={handleEditUser}
                />

            )}
        </Box>
    );
};

export default AdminPage;
