import React from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Button,
  IconButton,
  Tooltip,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

interface Props {
  isMobile: boolean;
  selectedFiles: File[];
  handleFileChange: (files: File[]) => void;
  removeAllFiles: () => void;
  error: string;
}

const FileUploadInput: React.FC<Props> = ({
  isMobile,
  selectedFiles,
  handleFileChange,
  removeAllFiles,
  error,
}) => (
  <Paper
    sx={{
      p: isMobile ? 2 : 3,
      mb: 3,
      backgroundColor: '#f9fafb',
      border: '1px solid #e0e0e0',
      borderRadius: '8px',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    }}
  >
    <Grid container alignItems="center" spacing={isMobile ? 1 : 2}>
      <Grid item>
        <CloudUploadIcon fontSize={isMobile ? 'medium' : 'large'} sx={{ color: '#a5a5a5' }} />
      </Grid>
      <Grid item xs>
        <Typography variant={isMobile ? 'body2' : 'body1'}>
          Drag and drop files here
        </Typography>
        <Typography variant="caption" color="textSecondary">
          Max file size: 10MB
        </Typography>
      </Grid>
      <Grid item>
        <input
          accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/vnd.ms-excel,application/pdf"
          style={{ display: 'none' }}
          id="upload-file-input"
          type="file"
          multiple
          onChange={(e) => handleFileChange(Array.from(e.target.files || []))}
        />
        <label htmlFor="upload-file-input">
          <Button
            variant="outlined"
            component="span"
            sx={{ textTransform: 'none', borderRadius: '20px' }}
          >
            Browse Files
          </Button>
        </label>
        <Tooltip title="Help with file upload">
          <IconButton size="small" sx={{ ml: 1 }}>
            <HelpOutlineIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>

    {selectedFiles.length > 0 && (
      <>
        <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
          Selected files: {selectedFiles.map((file) => file.name).join(', ')}
        </Typography>
        <Button variant="outlined" color="primary" onClick={removeAllFiles} sx={{ mt: 2 }}>
          Remove all files
        </Button>
      </>
    )}

    {error && (
      <Typography variant="body2" color="error" sx={{ mt: 1 }}>
        {error}
      </Typography>
    )}
  </Paper>
);

export default FileUploadInput;
