import axios from 'axios';
import { BASE_URL_PROD, BASE_URL_QA } from '../const';

// Generates an image based on a prompt, size, and style.
export const generateImage = async (prompt: string, size: string, style: string) => {
  const payload = {
    prompt,
    size,
    n: 1,
    quality: 'hd',
    style,
  };

  // Send a POST request to the image generation API with the payload.
  const response = await axios.post(
    `${BASE_URL_QA}/api/generate-image`,
    payload,
    { responseType: 'blob' }
  );

  const contentType = response.headers['content-type'];
  if (!contentType.startsWith('image/')) {
    throw new Error('Resposta não é uma imagem.');
  }

  return URL.createObjectURL(response.data);
};

// Sends an image URL and a prompt to the server to get a description of the image.
export const describeImage = async (imageUrl: string, prompt: string) => {
  const payload = {
    imageUrl,
    prompt,
  };

  const response = await axios.post(`${BASE_URL_QA}/describe-image`, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response.data.description || 'Nenhuma descrição retornada.';
};
