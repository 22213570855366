import { useState, useEffect, useRef } from 'react';
import { getFilesByTeam, getFileUrlByName, uploadFilesWithQuestion } from '../services/fileUploadService';

interface FileAnalysis {
  fileName: string;
  result: string;
}

interface ChatResponse {
  question: string;
  answers: FileAnalysis[];
}

// Custom hook for handling file uploads and interactions with an AI service.
export const useFileUpload = (team: string) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [filesFetched, setFilesFetched] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [question, setQuestion] = useState<string>('');
  const [responses, setResponses] = useState<ChatResponse[]>(() => {
    const savedResponses = localStorage.getItem('chatResponses');
    return savedResponses ? JSON.parse(savedResponses) : [];
  });
  const responsesEndRef = useRef<HTMLDivElement | null>(null);

  const [fetchedFiles, setFetchedFiles] = useState<any[]>([]);
  const [loadingFiles, setLoadingFiles] = useState<boolean>(false);


  // Effect to fetch files by team whenever the team changes.
  useEffect(() => {
    const fetchFiles = async () => {
      setLoadingFiles(true);
      try {
        const response = await getFilesByTeam(team);
        if (response?.files) {
          setFetchedFiles(response.files);
        } else {
          console.error('Unexpected response structure:', response);
        }
      } catch (error) {
        console.error('Error fetching files:', error);
      } finally {
        setLoadingFiles(false);
      }
    };

    if (team) {
      fetchFiles();
    }
  }, [team]);

  // Effect to automatically scroll to the bottom of the chat when responses change.
  useEffect(() => {
    scrollToBottom();
  }, [responses]);

  const scrollToBottom = () => {
    if (responsesEndRef.current) {
      responsesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

   // Handles file selection and validates file types.
  const handleFileChange = (files: File[]) => {
    const allowedFileTypes = [
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/msword',
      'application/vnd.ms-excel',
      'application/pdf',
    ];

    const validFiles = files.filter((file) => allowedFileTypes.includes(file.type));
    if (validFiles.length === 0) {
      setError('Only Word, Excel, and PDF files are allowed.');
    } else {
      setError('');
      setSelectedFiles((prevFiles) => [...prevFiles, ...validFiles]);
    }
  };

  // Removes all selected files.
  const removeAllFiles = () => {
    setSelectedFiles([]);
  };

  // Saves chat responses to local storage.
  const saveResponsesToLocalStorage = (newResponses: ChatResponse[]) => {
    localStorage.setItem('chatResponses', JSON.stringify(newResponses));
  };


  // Handles file upload and interaction with the AI service.
  const handleUpload = async (model: string, temperature: number, maxTokens: number) => {
    if (!question.trim()) {
      setError('Please write a question.');
      return;
    }

    setUploading(true);
    setLoading(true);
    setError('');

    try {
      let existingFileUrl = '';

      const fileNameMatch = question.match(/(\w+\.\w{2,4})/);
      if (fileNameMatch && fileNameMatch[1] && selectedFiles.length === 0) {
        const fileName = fileNameMatch[1].toLowerCase();
        const response = await getFileUrlByName(team, fileName);
        existingFileUrl = response?.url || '';

        if (existingFileUrl) {
          const result = await uploadFilesWithQuestion(
            [],
            question.trim(),
            model,
            temperature,
            maxTokens,
            existingFileUrl,
            team
          );

          const newResponse: ChatResponse = {
            question: question.trim(),
            answers: Array.isArray(result) ? result : [],
          };

          const updatedResponses = [...responses, newResponse];
          setResponses(updatedResponses);
          saveResponsesToLocalStorage(updatedResponses);

          setQuestion('');
          return;
        }
      }

      const result = await uploadFilesWithQuestion(
        selectedFiles,
        question.trim(),
        model,
        temperature,
        maxTokens,
        '',
        team
      );

      const newResponse: ChatResponse = {
        question: question.trim(),
        answers: Array.isArray(result) ? result : [],
      };

      const updatedResponses = [...responses, newResponse];
      setResponses(updatedResponses);
      saveResponsesToLocalStorage(updatedResponses);

      setQuestion('');
    } catch (err: any) {
      setError(err.response?.data?.message || 'An error occurred while uploading.');
    } finally {
      setLoading(false);
      setUploading(false);
    }
  };

  // Clears all chat history and removes it from local storage.
  const clearHistory = () => {
    setResponses([]);
    localStorage.removeItem('chatResponses');
  };

  return {
    selectedFiles,
    error,
    question,
    loading,
    uploading,
    responses,
    responsesEndRef,
    setQuestion,
    handleFileChange,
    removeAllFiles,
    handleUpload,
    clearHistory,
    fetchedFiles,
    loadingFiles,
  };
};
