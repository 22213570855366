import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: "148cae11-aa87-433f-b9a0-5d1350316383",
    authority: "https://login.microsoftonline.com/ecaa386b-c8df-4ce0-ad01-740cbdb5ba55",
    redirectUri: "https://qa.saregsolutions.basf.com",
    //redirectUri: "http://localhost:3000",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            break;
          case LogLevel.Info:
            console.info(message);
            break;
          case LogLevel.Verbose:
            console.debug(message);
            break;
          case LogLevel.Warning:
            console.warn(message);
            break;
          default:
            break;
        }
      },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false,
    },
  },
};

export const loginRequest = {
  scopes: ["User.Read.All", "api://148cae11-aa87-433f-b9a0-5d1350316383/access_as_user"],
};
