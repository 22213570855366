import { FC } from 'react';
import {
  Box,
  Typography,
  TextField,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
  CircularProgress,
  InputAdornment
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HomeIcon from '@mui/icons-material/Home';
import SendIcon from '@mui/icons-material/Send';
import { useImageDescription } from '../hooks/useImageDescription';
import { useLocation } from 'react-router-dom';

const TalkToImagePage: FC = () => {
  const theme = useTheme();
  const location = useLocation();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const selectedOption = location.state?.selectedOption; 

  const {
    imageUrl,
    setImageUrl,
    apiResponse,
    loading,
    error,
    handleSend,
  } = useImageDescription();

  return (
    <Box display="flex" flexDirection={isMobile ? 'column' : 'row'}>
      <Box
        sx={{
          width: isMobile ? '100%' : '300px',
          padding: '24px',
          height: isMobile ? 'auto' : '100vh',
          backgroundColor: '#f6f8fa',
          borderRight: isMobile ? 'none' : '1px solid #e0e0e0',
          borderBottom: isMobile ? '1px solid #e0e0e0' : 'none',
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          mb: isMobile ? 2 : 0,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Customize your app
        </Typography>
        <div>
          Instance: <label>{selectedOption}</label>
        </div>
        {[
          'Describe an application architecture.',
          'Explain a diagram.',
          'Understand how a graph evolves throughout time.',
          "Tell what's happening in a scene."
        ].map((text, index) => (
          <Typography key={index} variant="body2">
            • {text}
          </Typography>
        ))}
      </Box>

      <Box flexGrow={1} p={isMobile ? 2 : 4}>
        <IconButton onClick={() => window.location.href = '/'} aria-label="Home">
          <HomeIcon />
        </IconButton>

        <Typography variant="h4" sx={{ mt: 2 }} display="flex" alignItems="center">
          <span role="img" aria-label="eyes">👀</span> Talk to your image
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" sx={{ mb: 4 }}>
          Get insights from your image with a simple question.
        </Typography>

        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ mb: 2, backgroundColor: '#fff8e1', padding: '8px', borderRadius: '4px' }}
        >
          Please enter the URL of your image.
        </Typography>

        <TextField
          fullWidth
          variant="outlined"
          label="Image URL"
          placeholder="https://example.com/your-image.jpg"
          sx={{ mt: 2 }}
          value={imageUrl}
          onChange={(e) => setImageUrl(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="send"
                  onClick={handleSend}
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} /> : <SendIcon />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />

        {apiResponse && (
          <Box sx={{ mt: 4, p: 2, backgroundColor: '#e3f2fd', borderRadius: '8px' }}>
            <Typography variant="h6">Response:</Typography>
            <Typography variant="body1">{apiResponse}</Typography>
          </Box>
        )}

        {error && (
          <Box sx={{ mt: 4, p: 2, backgroundColor: '#ffebee', borderRadius: '8px' }}>
            <Typography variant="h6" color="error">Error:</Typography>
            <Typography variant="body1" color="error">{error}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TalkToImagePage;
