import { FC, ReactNode } from 'react';
import { Container, Box, Typography } from '@mui/material';

interface PortalLayoutProps {
  children: ReactNode;
}

const PortalLayout: FC<PortalLayoutProps> = ({ children }) => {
  return (
    <Container maxWidth="md" sx={{ textAlign: 'left', px: 3 }}>
      <Box my={4}>
        <Typography variant="h3" component="h1" gutterBottom>
          AI LAB South America
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" sx={{ maxWidth: 600 }}>
          Discover the power of Large Language Models and Multimodal Models in this playground, securely and anonymously.
        </Typography>
      </Box>
      <Box my={4}>
        {children}
      </Box>
    </Container>
  );
};

export default PortalLayout;
