import React, { FC, useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  useMediaQuery,
  useTheme,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate, useLocation } from 'react-router-dom';
import CustomizeAppSidebar from '../components/CustomizeAppSidebar';
import { useFileUpload } from '../hooks/useFileUpload';
import FileUploadActions from '../components/FileUploadActions';
import FileUploadInput from '../components/FileUploadInput';
import ResponseDisplay from '../components/ResponseDisplay';
import Instructions from '../components/Instructions';

const DataUploadPage: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { selectedOption: team } = location.state || { selectedOption: 'N/A' };

  const {
    selectedFiles,
    error,
    question,
    loading,
    responses,
    responsesEndRef,
    setQuestion,
    handleFileChange,
    removeAllFiles,
    handleUpload,
    clearHistory,
    fetchedFiles,
    loadingFiles,
  } = useFileUpload(team);

  const [model, setModel] = useState<string>('gpt-4');
  const [temperature, setTemperature] = useState<number>(0.2);
  const [maxTokens, setMaxTokens] = useState<number>(750);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [historyModalOpen, setHistoryModalOpen] = useState<boolean>(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const openHistoryModal = () => {
    setHistoryModalOpen(true);
  };

  const closeHistoryModal = () => {
    setHistoryModalOpen(false);
  };

  return (
    <Box display="flex" height="100vh" overflow="hidden" flexDirection={isMobile ? 'column' : 'row'}>
      {isMobile ? (
        <>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer}
            sx={{ position: 'absolute', top: 16, left: 16, zIndex: 1300 }}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            anchor="left"
            open={drawerOpen}
            onClose={toggleDrawer}
            ModalProps={{ keepMounted: true }}
            sx={{ '& .MuiDrawer-paper': { width: isMobile ? '75%' : 240 } }}
          >
            <CustomizeAppSidebar
              fields={['model', 'temperature', 'maxTokens']}
              model={model}
              setModel={setModel}
              temperature={temperature}
              setTemperature={setTemperature}
              maxTokens={maxTokens}
              setMaxTokens={setMaxTokens}
              selectedOption={team}
            />
          </Drawer>
        </>
      ) : (
        <Box
          sx={{ width: 322, flexShrink: 0, borderRight: '1px solid #e0e0e0', height: '100vh' }}
        >
          <CustomizeAppSidebar
            fields={['model', 'temperature', 'maxTokens']}
            model={model}
            setModel={setModel}
            temperature={temperature}
            setTemperature={setTemperature}
            maxTokens={maxTokens}
            setMaxTokens={setMaxTokens}
            selectedOption={team}
          />
        </Box>
      )}

      <Box flexGrow={1} p={isMobile ? 2 : 4} display="flex" flexDirection="column" overflow="auto">
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, justifyContent: isMobile ? 'center' : 'flex-start' }}>
          <IconButton onClick={() => navigate('/')} aria-label="Home">
            <HomeIcon />
          </IconButton>
          <Typography variant={isMobile ? 'h5' : 'h4'} sx={{ ml: 2 }}>
            Talk to your own files
          </Typography>
        </Box>

        <FileUploadActions
          isMobile={isMobile}
          clearHistory={clearHistory}
          openHistoryModal={openHistoryModal}
        />

        {/* Seção para exibir os nomes dos arquivos */}
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6">Uploaded Files:</Typography>
          {loadingFiles ? (
            <Typography>Loading files...</Typography>
          ) : (
            <List>
              {fetchedFiles.map((fileUrl: string, index: number) => {
                const fileName = decodeURIComponent(fileUrl.split('/').pop()?.split('?')[0] || 'Unknown File');
                return (
                  <ListItem key={index}>
                    <ListItemText primary={fileName} />
                  </ListItem>
                );
              })}
            </List>
          )}
        </Box>
        <Instructions />

        <FileUploadInput
          isMobile={isMobile}
          selectedFiles={selectedFiles}
          handleFileChange={handleFileChange}
          removeAllFiles={removeAllFiles}
          error={error}
        />

        <ResponseDisplay
          responses={responses}
          question={question}
          setQuestion={setQuestion}
          handleUpload={() => handleUpload(model, temperature, maxTokens)}
          loading={loading}
          responsesEndRef={responsesEndRef}
          isMobile={isMobile}
        />
      </Box>
    </Box>
  );
};

export default DataUploadPage;
