import { useState, useEffect } from 'react';
import { generateImage } from '../services/imageService';

// Custom hook for handling image generation logic.
export const useImageGeneration = () => {
  const [prompt, setPrompt] = useState('');
  const [size, setSize] = useState('1024x1024');
  const [style, setStyle] = useState('vivid');
  const [loading, setLoading] = useState(false);
  const [imageURL, setImageURL] = useState<string | null>(null);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: 'success' | 'error';
  }>({
    open: false,
    message: '',
    severity: 'success',
  });
  const [modalOpen, setModalOpen] = useState(false);

  // Effect to clean up the generated image URL when the component unmounts.
  useEffect(() => {
    return () => {
      if (imageURL) {
        URL.revokeObjectURL(imageURL);
      }
    };
  }, [imageURL]);

  // Function to handle the image generation request.
  const handleGenerateImage = async () => {
    if (!prompt.trim()) {
      setSnackbar({
        open: true,
        message: 'Please enter a description for the image.',
        severity: 'error',
      });
      return;
    }

    setLoading(true);
    setImageURL(null);

    try {
      const generatedImageUrl = await generateImage(prompt, size, style);
      setImageURL(generatedImageUrl);

      setSnackbar({
        open: true,
        message: 'Image generated successfully!',
        severity: 'success',
      });
    } catch (error: any) {
      console.error('Error generating image:', error);
      setSnackbar({
        open: true,
        message: 'Error generating image. Please try again.',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  // Function to open the modal.
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  // Function to close the modal.
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return {
    prompt,
    setPrompt,
    size,
    setSize,
    style,
    setStyle,
    loading,
    imageURL,
    snackbar,
    modalOpen,
    setSnackbar,
    handleGenerateImage,
    handleOpenModal,
    handleCloseModal,
  };
};
