import React, { FC } from 'react';
import {
  Box,
  Typography,
  Select,
  MenuItem,
  Slider,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
  SxProps,
  Theme,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

interface CustomizeAppSidebarProps {
  fields?: string[];
  model: string;
  setModel: (model: string) => void;
  temperature: number;
  setTemperature: (temp: number) => void;
  maxTokens: number;
  setMaxTokens: (maxTokens: number) => void;
  sx?: SxProps<Theme>;
  selectedOption?: string;
}

const CustomizeAppSidebar: FC<CustomizeAppSidebarProps> = ({
  fields = [],
  model,
  setModel,
  temperature,
  setTemperature,
  maxTokens,
  setMaxTokens,
  sx,
  selectedOption,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const renderField = (
    label: string,
    tooltip: string,
    element: React.ReactNode
  ) => (
    <>
      <Box display="flex" alignItems="center" mt={2}>
        <Typography variant="subtitle2" sx={{ flexGrow: 1 }}>
          {label}
        </Typography>
        <Tooltip title={tooltip}>
          <IconButton size="small" aria-label={`Help for ${label}`}>
            <HelpOutlineIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </Box>
      {element}
    </>
  );

  return (
    <Box
      sx={{
        width: isMobile ? '100%' : '300px',
        padding: '16px',
        height: '100vh',
        backgroundColor: '#f6f8fa',
        borderRight: '1px solid #e0e0e0',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        overflowY: 'auto',
        ...sx,
      }}
    >
      <Typography variant="h6" style={{ marginTop: '2rem' }} gutterBottom>
        Customize your app
      </Typography>

      <div>
        Instances: <label>{selectedOption}</label>
      </div>

      {fields.includes('model') &&
        renderField(
          'Model',
          'Select the model to use',
          <Select
            value={model}
            onChange={(e) => setModel(e.target.value as string)}
            fullWidth
          >
            <MenuItem value="gpt-4o-mini">gpt-4o-mini</MenuItem>
            <MenuItem value="gpt-35-turbo">gpt-35-turbo</MenuItem>
            <MenuItem value="gpt-35-turbo-16k">gpt-35-turbo-16k</MenuItem>
          </Select>
        )}

      {fields.includes('temperature') &&
        renderField(
          'Temperature',
          'Controls the randomness of responses',
          <>
            <Slider
              value={temperature}
              onChange={(e, value) => setTemperature(value as number)}
              min={0}
              max={1}
              step={0.01}
              sx={{ width: '100%' }}
              aria-labelledby="temperature-slider"
            />
            <Typography variant="body2" color="textSecondary">
              {temperature.toFixed(2)}
            </Typography>
          </>
        )}

      {fields.includes('maxTokens') &&
        renderField(
          'Max tokens response',
          'Maximum number of tokens in the response',
          <>
            <Slider
              value={maxTokens}
              onChange={(e, value) => setMaxTokens(value as number)}
              min={250}
              max={4000}
              step={50}
              sx={{ width: '100%' }}
              aria-labelledby="max-tokens-slider"
            />
            <Typography variant="body2" color="textSecondary">
              {maxTokens}
            </Typography>
          </>
        )}
    </Box>
  );
};

export default CustomizeAppSidebar;
