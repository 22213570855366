import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PortalLayout from '../components/HeaderHome';
import FeatureButton from '../components/FeatureButton';
import {
    Box,
    useMediaQuery,
    useTheme,
    Typography,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    AppBar,
    Toolbar,
    IconButton,
    Button,
    Grid,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import useAuth from '../hooks/useAuth';

const drawerWidth = 240;

const PortalContainer: FC = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const {
        logout,
        roles,
        isRoleSelectionNeeded,
        setSelectedRoles,
        handleRoleSelectionSubmit,
    } = useAuth();

    const [selectedOption, setSelectedOption] = useState<string | null>(null);
    const [mobileOpen, setMobileOpen] = useState(false);

    const availableRoles = ['GB', 'PM', 'GD', 'AP', 'ECD', 'EM', 'EN', 'EV', 'ED', 'GP'];

    // Verifica se o usuário tem a role de admin e, se tiver, concede acesso a todas as roles
    const rolesToDisplay = roles.includes('admin') ? availableRoles : roles;

    const handleLogout = () => {
        logout()
            .then(() => {
                navigate('/login');
            })
            .catch((error) => {
                console.error("Erro ao realizar logout:", error);
            });
    };

    const handleNavigate = (path: string) => {
        if (selectedOption) {
            navigate(path, { state: { selectedOption } });
        }
    };

    const handleOptionSelect = (option: string) => {
        setSelectedOption(option);
        if (isMobile) {
            setMobileOpen(false);
        }
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleNavigateToAdmin = () => {
        navigate('/admin');
    };

    const drawer = (
        <div>
            <Toolbar>
                <Typography variant="h6" noWrap component="div">
                    Roles
                </Typography>
            </Toolbar>
            <List>
                {rolesToDisplay.map((role) => (
                    <ListItem key={role} disablePadding>
                        <ListItemButton
                            selected={selectedOption === role}
                            onClick={() => handleOptionSelect(role)}
                        >
                            <ListItemText primary={role} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            {!isMobile && (
                <Box sx={{ p: 2 }}>
                    <Button variant="contained" color="error" fullWidth onClick={handleLogout}>
                        Logout
                    </Button>
                    {roles.includes('admin') && (
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handleNavigateToAdmin}
                            sx={{ marginTop: 2 }}
                        >
                            ADM
                        </Button>
                    )}
                </Box>
            )}
        </div>
    );

    return (
        <PortalLayout>

            <>
                {isMobile && (
                    <AppBar position="fixed" sx={{ zIndex: theme.zIndex.drawer + 1 }}>
                        <Toolbar>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{ mr: 2 }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                                Roles
                            </Typography>
                            <Button color="inherit" onClick={handleLogout}>
                                Logout
                            </Button>
                        </Toolbar>
                    </AppBar>
                )}

                <Box sx={{ display: 'flex' }}>
                    {isMobile ? (
                        <Drawer
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            ModalProps={{
                                keepMounted: true,
                            }}
                            sx={{
                                display: { xs: 'block', sm: 'none' },
                                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                            }}
                        >
                            {drawer}
                        </Drawer>
                    ) : (
                        <Drawer
                            variant="permanent"
                            sx={{
                                width: drawerWidth,
                                flexShrink: 0,
                                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                            }}
                        >
                            {drawer}
                        </Drawer>
                    )}

                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            p: 3,
                            marginTop: isMobile ? '64px' : 0,
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="flex-start" // Alinhamento à esquerda
                                    gap={2}
                                    width="100%"
                                >
                                    <FeatureButton
                                        title="Talk to your data"
                                        onClick={() => handleNavigate('/data-upload')}
                                    />
                                    <FeatureButton
                                        title="Talk to your image"
                                        onClick={() => handleNavigate('/talk-to-image')}
                                    />
                                    <FeatureButton
                                        title="Text to image"
                                        onClick={() => handleNavigate('/generate-images')}
                                    />
                                </Box>

                            </Grid>
                        </Grid>

                        <Box borderBottom="1px solid #d3d3d3" mt={2} />
                    </Box>
                </Box>
            </>

        </PortalLayout>
    );
};

export default PortalContainer;
