import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import DataUploadPage from './pages/FileUploadPage';
import PortalContainer from './pages/HomePage';
import TalkToBaseLLMsPage from './pages/ChatPage';
import TalkToImagePage from './pages/ImagePage';
import GenerateImagesPage from './pages/GenImagePage';
//import AudioPortal from './pages/AudioPage';
import LoginPage from './pages/LoginPage';
import useAuth from './hooks/useAuth';
import AdminPage from './pages/AdminPage';

const App: React.FC = () => {
  const { isAuthenticated } = useAuth();

  return (
    <Routes>
      <Route 
        path="/login" 
        element={!isAuthenticated ? <LoginPage /> : <Navigate to="/" />} 
      />
      <Route 
        path="/" 
        element={isAuthenticated ? <PortalContainer /> : <Navigate to="/login" />} 
      />
      <Route 
        path="/data-upload" 
        element={isAuthenticated ? <DataUploadPage /> : <Navigate to="/login" />} 
      />
      <Route 
        path="/talk-to-base-llms" 
        element={isAuthenticated ? <TalkToBaseLLMsPage /> : <Navigate to="/login" />} 
      />
      <Route 
        path="/talk-to-image" 
        element={isAuthenticated ? <TalkToImagePage /> : <Navigate to="/login" />} 
      />
      <Route 
        path="/generate-images" 
        element={isAuthenticated ? <GenerateImagesPage /> : <Navigate to="/login" />} 
      />
      {/* <Route 
        path="/audio-ai" 
        element={isAuthenticated ? <AudioPortal /> : <Navigate to="/login" />} 
      /> */}
      <Route 
        path="/admin" 
        element={isAuthenticated ? <AdminPage /> : <Navigate to="/login" />} 
      />
      <Route path="*" element={<Navigate to={isAuthenticated ? "/" : "/login"} />} />
    </Routes>
  );
};

export default App;
