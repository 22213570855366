import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import jwt_decode from 'jwt-decode';
import { getUserByEmail, addUser } from '../services/userService';

interface DecodedToken {
  oid: string;
  preferred_username: string;
  name: string;
  unique_name: string;
  [key: string]: any;
}

const useAuth = () => {
  const { instance, accounts } = useMsal();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [token, setToken] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [userDetails, setUserDetails] = useState<DecodedToken | null>(null);
  const [roles, setRoles] = useState<string[]>([]);
  const [isRoleSelectionNeeded, setIsRoleSelectionNeeded] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      handleToken(storedToken);
    } else if (accounts.length > 0) {
      instance.acquireTokenSilent({
        scopes: ["User.Read"],
        account: accounts[0],
      })
        .then(response => {
          localStorage.setItem('token', response.accessToken);
          handleToken(response.accessToken);
        })
        .catch(() => {
          setIsAuthenticated(false);
        });
    }
  }, [instance, accounts]);

  const handleToken = (accessToken: string) => {
    setToken(accessToken);
    setIsAuthenticated(true);

    try {
      const decodedToken = jwt_decode<DecodedToken>(accessToken);
      const userEmail = decodedToken.unique_name || decodedToken.preferred_username;
      setEmail(userEmail);
      setUserDetails(decodedToken);
      localStorage.setItem('email', userEmail);
      syncUser(userEmail, decodedToken);
    } catch (error) {
      console.error('Erro ao decodificar o token:', error);
    }
  };

  const syncUser = async (email: string, decodedToken: DecodedToken) => {
    try {
      const user = await getUserByEmail(email);
      if (!user) {
        setIsRoleSelectionNeeded(true);
      } else {
        setRoles(user.roles || []);
      }
    } catch (error) {
      console.error('Erro ao buscar usuário pelo email:', error);
    }
  };

  const handleRoleSelectionSubmit = async () => {
    if (userDetails) {
      const newUser = {
        email: userDetails.unique_name || userDetails.preferred_username,
        name: userDetails.name,
        roles: selectedRoles,
      };
      try {
        await addUser(newUser);
        setRoles(selectedRoles);
        setIsRoleSelectionNeeded(false);
      } catch (error) {
        console.error('Erro ao adicionar usuário:', error);
      }
    }
  };

  const logout = (): Promise<void> => {
    return instance.logoutPopup().then(() => {
      localStorage.removeItem('token');
      localStorage.removeItem('email');
      setIsAuthenticated(false);
      setToken(null);
      setEmail(null);
      setUserDetails(null);
      setRoles([]);
      window.location.reload();
    });
  };

  return {
    isAuthenticated,
    token,
    email,
    userDetails,
    roles,
    isRoleSelectionNeeded,
    setSelectedRoles,
    handleRoleSelectionSubmit,
    logout,
  };
};

export default useAuth;
