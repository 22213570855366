// src/pages/LoginPage.tsx

import React from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import { useNavigate } from 'react-router-dom';

const LoginPage: React.FC = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await instance.loginPopup(loginRequest);
      if (response.accessToken) {
        localStorage.setItem('token', response.accessToken);
        navigate('/');
      } else {
        console.error("Token de acesso não encontrado na resposta.");
      }
    } catch (error) {
      console.error("Erro durante o login:", error);
    }
  };

  return (
    <div style={styles.container}>
      <button onClick={handleLogin} style={styles.button}>
        Sign with Smart card
      </button>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    backgroundColor: '#007BFF',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
};

export default LoginPage;
