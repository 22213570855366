import axios from 'axios';
import { BASE_URL_QA } from '../const';

// Uploads files along with a question to the server for processing.
export const uploadFilesWithQuestion = async (
  files: File[],
  question: string,
  model: string,
  temperature: number,
  maxTokens: number,
  existingFileUrl: string = '',
  team: string
) => {
  const formData = new FormData();
  files.forEach((file) => formData.append('files', file));
  formData.append('question', question);
  formData.append('model', model);
  formData.append('temperature', temperature.toString());
  formData.append('max_tokens_response', maxTokens.toString());
  formData.append('team', `groups-${team.toLowerCase()}`);
  if (existingFileUrl) {
    formData.append('existing_file_url', existingFileUrl);
  }


  // Make a POST request to upload the files and data to the specified endpoint.
  const response = await axios.post(`${BASE_URL_QA}/api/upload-files`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response.data;
};

// Fetches a list of files related to a specific team from the server.
export const getFilesByTeam = async (team: string) => {
  try {
    const teamParam = `groups-${team.toLowerCase()}`;
    const response = await axios.get(`${BASE_URL_QA}/api/blob-files/${teamParam}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching files:', error);
    throw error;
  }
};

// Fetches the URL of a specific file by name for a given team.
export const getFileUrlByName = async (team: string, fileName: string) => {
  try {
    const teamParam = `groups-${team.toLowerCase()}`;
    const response = await axios.get(`${BASE_URL_QA}/api/files/${teamParam}/${fileName}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching file URL by name:', error);
    throw error;
  }
};
