import { useState } from 'react';
import { describeImage } from '../services/imageService';

// Custom hook for handling image description logic.
export const useImageDescription = () => {
  const [imageUrl, setImageUrl] = useState('');
  const [apiResponse, setApiResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // Helper function to validate if a string is a proper URL.
  const isValidUrl = (urlString: string) => {
    try {
      return Boolean(new URL(urlString));
    } catch (e) {
      return false;
    }
  };

  // Function to handle sending the image URL for description.
  const handleSend = async () => {
    if (!isValidUrl(imageUrl)) {
      setError('Please provide a valid image URL.');
      return;
    }

    setLoading(true);
    setError('');
    setApiResponse('');

    try {
      const description = await describeImage(imageUrl, 'Describe the content of the image below:');
      setApiResponse(description);
    } catch (err: any) {
      console.error(err);
      setError('An error occurred while processing your request.');
    } finally {
      setLoading(false);
    }
  };

  return {
    imageUrl,
    setImageUrl,
    apiResponse,
    loading,
    error,
    handleSend,
  };
};
