import React from 'react';
import { Modal, Box, Typography, TextField, Button } from '@mui/material';

interface UserModalProps {
    open: boolean;
    onClose: () => void;
    title: string;
    user: { email: string; name: string; roles: string };
    onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onSubmit: () => void;
}

const UserModal: React.FC<UserModalProps> = ({ open, onClose, title, user, onInputChange, onSubmit }) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{ p: 4, bgcolor: 'background.paper', margin: 'auto', marginTop: '10%', maxWidth: 500 }}>
                <Typography variant="h6" gutterBottom>
                    {title}
                </Typography>
                <TextField
                    label="Email"
                    name="email"
                    fullWidth
                    margin="normal"
                    value={user.email}
                    onChange={onInputChange}
                />
                <TextField
                    label="Name"
                    name="name"
                    fullWidth
                    margin="normal"
                    value={user.name}
                    onChange={onInputChange}
                />
                <TextField
                    label="Roles (comma-separated)"
                    name="roles"
                    fullWidth
                    margin="normal"
                    value={user.roles}
                    onChange={onInputChange}
                />
                <Button variant="contained" color="primary" onClick={onSubmit} sx={{ mt: 2 }}>
                    Save
                </Button>
            </Box>
        </Modal>
    );
};

export default UserModal;
